import gameTypes from "./game.types";
export const newGameScreen = () => {
  return {
    type: gameTypes.NEW_GAME_SCREEN
  };
};
export const gameOverScreen = () => {
  return {
    type: gameTypes.GAME_OVER
  };
};
export const blockKeyboard = () => {
  return {
    type: gameTypes.BLOCK_KEYS
  };
};
