import keyboardTypes from "./keyboard.types";
const INITIAL_STATE = {
  topKeys: ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"],
  midKeys: ["A", "S", "D", "F", "G", "H", "J", "K", "L", ";"],
  botKeys: ["Z", "X", "C", "V", "B", "N", "M", "<", ">", "/"],
  isBlur: false
};
const copyState = {
  topKeys: ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"],
  midKeys: ["A", "S", "D", "F", "G", "H", "J", "K", "L", ";"],
  botKeys: ["Z", "X", "C", "V", "B", "N", "M", "<", ">", "/"],
  isBlur: false
};

const keyboardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case keyboardTypes.REORDER_TOP:
      return { ...state, topKeys: [...state.topKeys.reverse()] };
    case keyboardTypes.REORDER_MID:
      return { ...state, midKeys: [...state.midKeys.reverse()] };
    case keyboardTypes.REORDER_BOT:
      return { ...state, botKeys: [...state.botKeys.reverse()] };
    case keyboardTypes.BLUR_KEYS:
      return { ...state, isBlur: true };
    case keyboardTypes.KEYS_RESET:
      return {
        topKeys: [...copyState.topKeys],
        midKeys: [...copyState.midKeys],
        botKeys: [...copyState.botKeys],
        isBlur: false
      };
    default:
      return state;
  }
};

export default keyboardReducer;
