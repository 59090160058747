import "./App.css";
import React, { Component } from "react";
import Keyboard from "./components/keyboard/keyboard.component";
import Screen from "./components/screen/screen.component";
import Time from "./components/time/time.component";
import Minigames from "./components/minigames/minigames.component";
import { connect } from "react-redux";
import { newGameScreen } from "./redux/game/game.actions";
import { newGame } from "./redux/letters/letters.actions";

class App extends Component {
  newGame = () => {
    this.props.newGameScreen();
  };
  tryAgain = () => {
    this.props.newGameScreen();
    this.props.newGame();
  };

  render() {
    return (
      <div className="App">
        {this.props.activeGame ? (
          <div>
            <h1 className="main-title">Homeoffice from hell</h1>
            <div className="app-holder">
              <div className="time-holder">
                <Time />
              </div>
              <div className="game-holder">
                <Screen />
                <Keyboard />
              </div>
              <div className="action-holder">
                <Minigames />
              </div>
            </div>
          </div>
        ) : null}
        {this.props.startScreen ? (
          <div className="start-screen">
            <div>
              <h2>
                Homeoffice <br /> from hell
              </h2>
              <button onClick={this.newGame}>Start</button>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  startScreen: state.game.startScreen,
  gameOver: state.game.gameOver,
  wellDone: state.game.wellDone,
  activeGame: state.game.activeGame
});

const mapDispatchToProps = dispatch => ({
  newGameScreen: () => dispatch(newGameScreen()),
  newGame: () => dispatch(newGame())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
