import minigamesTypes from "./minigames.types";
const INITIAL_STATE = {
  activeMinigame: null,
  alarmGame: null
};

const minigamesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case minigamesTypes.SET_ACTIVE_GAME:
      return { ...state, activeMinigame: action.payload };
    case minigamesTypes.SET_ALARM_GAME:
      return { ...state, alarmGame: action.payload };
    default:
      return state;
  }
};

export default minigamesReducer;
