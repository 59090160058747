import React from "react";
import "./rakija.style.scss";
import rakijaImg from "../../../images/rakija.png";
import rakijamini from "../../../images/rakijamini.png";

const Rakija = props => {
  let rakijeNo = [];
  let text = null;
  for (let i = 0; i < props.rakije; i++) {
    rakijeNo.push(<img key={i} src={rakijamini} alt={rakijamini} />);
  }

  return (
    <div className="rakija">
      <div
        className={`rakija-button ${props.rakije < 4 ? null : "have"}`}
        onClick={props.rakije < 4 ? props.addRakija : null}
      >
        <img src={rakijaImg} alt={rakijaImg} />
      </div>
      {props.rakije < 4 ? (
        <p className="instruction">
          Drink rakija for more time<span>(don't push)</span>
        </p>
      ) : (
        <p className="instruction">No more</p>
      )}

      <div className="drinked">{rakijeNo}</div>
    </div>
  );
};

export default Rakija;
