export const data = {
  currentPosition: 0,
  finished: false,
  firstLine: [
    { val: "<", active: true, done: false },
    {
      val: "p",
      active: false,
      done: false
    },
    {
      val: ">",
      active: false,
      done: false
    },
    {
      val: "i",
      active: false,
      done: false
    },
    {
      val: "n",
      active: false,
      done: false
    },
    {
      val: "t",
      active: false,
      done: false
    },
    {
      val: "e",
      active: false,
      done: false
    },
    {
      val: "r",
      active: false,
      done: false
    },
    {
      val: "v",
      active: false,
      done: false
    },
    {
      val: "e",
      active: false,
      done: false
    },
    {
      val: "n",
      active: false,
      done: false
    },
    {
      val: "t",
      active: false,
      done: false
    },
    {
      val: "u",
      active: false,
      done: false
    },
    {
      val: "r",
      active: false,
      done: false
    },
    {
      val: "e",
      active: false,
      done: false
    },
    {
      val: " ",
      active: false,
      done: false
    },
    {
      val: "h",
      active: false,
      done: false
    },
    {
      val: "a",
      active: false,
      done: false
    },
    {
      val: "c",
      active: false,
      done: false
    },
    {
      val: "k",
      active: false,
      done: false
    },
    {
      val: "a",
      active: false,
      done: false
    },
    {
      val: "t",
      active: false,
      done: false
    },
    {
      val: "o",
      active: false,
      done: false
    },
    {
      val: "n",
      active: false,
      done: false
    },
    {
      val: "<",
      active: false,
      done: false
    },
    {
      val: "/",
      active: false,
      done: false
    },
    {
      val: "p",
      active: false,
      done: false
    },
    {
      val: ">",
      active: false,
      done: false
    },
    {
      val: "<",
      active: false,
      done: false
    },
    {
      val: "p",
      active: false,
      done: false
    },
    {
      val: ">",
      active: false,
      done: false
    },
    {
      val: "b",
      active: false,
      done: false
    },
    {
      val: "r",
      active: false,
      done: false
    },
    {
      val: "e",
      active: false,
      done: false
    },
    {
      val: "a",
      active: false,
      done: false
    },
    {
      val: "k",
      active: false,
      done: false
    },
    {
      val: "i",
      active: false,
      done: false
    },
    {
      val: "n",
      active: false,
      done: false
    },
    {
      val: "g",
      active: false,
      done: false
    },
    {
      val: " ",
      active: false,
      done: false
    },
    {
      val: "s",
      active: false,
      done: false
    },
    {
      val: "o",
      active: false,
      done: false
    },
    {
      val: "c",
      active: false,
      done: false
    },
    {
      val: "i",
      active: false,
      done: false
    },
    {
      val: "a",
      active: false,
      done: false
    },
    {
      val: "l",
      active: false,
      done: false
    },
    {
      val: " ",
      active: false,
      done: false
    },
    {
      val: "b",
      active: false,
      done: false
    },
    {
      val: "a",
      active: false,
      done: false
    },
    {
      val: "r",
      active: false,
      done: false
    },
    {
      val: "r",
      active: false,
      done: false
    },
    {
      val: "i",
      active: false,
      done: false
    },
    {
      val: "e",
      active: false,
      done: false
    },
    {
      val: "r",
      active: false,
      done: false
    },
    {
      val: "s",
      active: false,
      done: false
    },
    {
      val: "<",
      active: false,
      done: false
    },
    {
      val: "/",
      active: false,
      done: false
    },
    {
      val: "p",
      active: false,
      done: false
    },
    {
      val: ">",
      active: false,
      done: false
    }
  ]
};

//interventure Hackathon
//Breaking Social Barriers
