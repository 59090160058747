import React, { Component } from "react";
import { connect } from "react-redux";
import "./keyboard.style.scss";
import KeyTile from "./keyTile/keyTile.component";
import Spacebar from "./spacebar/spacebar.component";
import {
  setActiveLetter,
  moveForvard
} from "../../redux/letters/letters.actions";

export class Keyboard extends Component {
  handleLetter = letter => {
    if (
      this.props.firstLine[this.props.currentPosition].val ===
      letter.toLowerCase()
    ) {
      this.props.moveForvard();
    } else {
    }
  };
  render() {
    return (
      <div>
        {!this.props.keyboardBlocked ? (
          <div className={`keyboard ${this.props.isBlur ? "blured" : ""}`}>
            <div className="keyboardRow">
              {this.props.topKeys.map((tile, index) => (
                <KeyTile
                  key={tile + index}
                  item={tile}
                  selectedLetter={() => {
                    this.handleLetter(tile);
                  }}
                />
              ))}
            </div>
            <div className="keyboardRow">
              {this.props.midKeys.map((tile, index) => (
                <KeyTile
                  key={tile + index}
                  item={tile}
                  selectedLetter={() => {
                    this.handleLetter(tile);
                  }}
                />
              ))}
            </div>
            <div className="keyboardRow">
              {this.props.botKeys.map((tile, index) => (
                <KeyTile
                  key={tile + index}
                  item={tile}
                  selectedLetter={() => {
                    this.handleLetter(tile);
                  }}
                />
              ))}
            </div>
            <Spacebar
              selectedLetter={() => {
                this.handleLetter(" ");
              }}
            />
          </div>
        ) : (
          <div className="blockedKeys">ALERT</div>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  currentPosition: state.letters.currentPosition,
  firstLine: state.letters.firstLine,
  keyboardBlocked: state.game.keyboardBlocked,
  topKeys: state.keyboard.topKeys,
  midKeys: state.keyboard.midKeys,
  botKeys: state.keyboard.botKeys,
  isBlur: state.keyboard.isBlur
});

const mapDispatchToProps = dispatch => ({
  setActiveLetter: letter => dispatch(setActiveLetter(letter)),
  moveForvard: () => dispatch(moveForvard())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Keyboard);
