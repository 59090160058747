import React from "react";
import "./keyTile.style.scss";

const KeyTile = props => {
  return (
    <div className="keyTile" onClick={props.selectedLetter}>
      {props.item}
    </div>
  );
};

export default KeyTile;
