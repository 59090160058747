import lettersTypes from "./letters.types";
import { data } from "./data";
const INITIAL_STATE = data;
const copyIS = {
  currentPosition: 0,
  finished: false,
  firstLine: [
    { val: "<", active: true, done: false },
    {
      val: "p",
      active: false,
      done: false
    },
    {
      val: ">",
      active: false,
      done: false
    },
    {
      val: "i",
      active: false,
      done: false
    },
    {
      val: "n",
      active: false,
      done: false
    },
    {
      val: "t",
      active: false,
      done: false
    },
    {
      val: "e",
      active: false,
      done: false
    },
    {
      val: "r",
      active: false,
      done: false
    },
    {
      val: "v",
      active: false,
      done: false
    },
    {
      val: "e",
      active: false,
      done: false
    },
    {
      val: "n",
      active: false,
      done: false
    },
    {
      val: "t",
      active: false,
      done: false
    },
    {
      val: "u",
      active: false,
      done: false
    },
    {
      val: "r",
      active: false,
      done: false
    },
    {
      val: "e",
      active: false,
      done: false
    },
    {
      val: " ",
      active: false,
      done: false
    },
    {
      val: "h",
      active: false,
      done: false
    },
    {
      val: "a",
      active: false,
      done: false
    },
    {
      val: "c",
      active: false,
      done: false
    },
    {
      val: "k",
      active: false,
      done: false
    },
    {
      val: "a",
      active: false,
      done: false
    },
    {
      val: "t",
      active: false,
      done: false
    },
    {
      val: "o",
      active: false,
      done: false
    },
    {
      val: "n",
      active: false,
      done: false
    },
    {
      val: "<",
      active: false,
      done: false
    },
    {
      val: "/",
      active: false,
      done: false
    },
    {
      val: "p",
      active: false,
      done: false
    },
    {
      val: ">",
      active: false,
      done: false
    },
    {
      val: "<",
      active: false,
      done: false
    },
    {
      val: "p",
      active: false,
      done: false
    },
    {
      val: ">",
      active: false,
      done: false
    },
    {
      val: "b",
      active: false,
      done: false
    },
    {
      val: "r",
      active: false,
      done: false
    },
    {
      val: "e",
      active: false,
      done: false
    },
    {
      val: "a",
      active: false,
      done: false
    },
    {
      val: "k",
      active: false,
      done: false
    },
    {
      val: "i",
      active: false,
      done: false
    },
    {
      val: "n",
      active: false,
      done: false
    },
    {
      val: "g",
      active: false,
      done: false
    },
    {
      val: " ",
      active: false,
      done: false
    },
    {
      val: "s",
      active: false,
      done: false
    },
    {
      val: "o",
      active: false,
      done: false
    },
    {
      val: "c",
      active: false,
      done: false
    },
    {
      val: "i",
      active: false,
      done: false
    },
    {
      val: "a",
      active: false,
      done: false
    },
    {
      val: "l",
      active: false,
      done: false
    },
    {
      val: " ",
      active: false,
      done: false
    },
    {
      val: "b",
      active: false,
      done: false
    },
    {
      val: "a",
      active: false,
      done: false
    },
    {
      val: "r",
      active: false,
      done: false
    },
    {
      val: "r",
      active: false,
      done: false
    },
    {
      val: "i",
      active: false,
      done: false
    },
    {
      val: "e",
      active: false,
      done: false
    },
    {
      val: "r",
      active: false,
      done: false
    },
    {
      val: "s",
      active: false,
      done: false
    },
    {
      val: "<",
      active: false,
      done: false
    },
    {
      val: "/",
      active: false,
      done: false
    },
    {
      val: "p",
      active: false,
      done: false
    },
    {
      val: ">",
      active: false,
      done: false
    }
  ]
};

const lettersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case lettersTypes.SET_ACTIVE_LETTER:
      return { ...state, activeLetter: action.payload };
    case lettersTypes.MOVE_FORVARD:
      let updatedFirstLine = [...state.firstLine];
      let finishedUpdated = state.finished;
      updatedFirstLine[state.currentPosition].active = false;
      updatedFirstLine[state.currentPosition].done = true;
      if (updatedFirstLine.length !== state.currentPosition + 1) {
        updatedFirstLine[state.currentPosition + 1].active = true;
      } else {
        finishedUpdated = true;
      }

      return {
        ...state,
        currentPosition: state.currentPosition + 1,
        firstLine: [...updatedFirstLine],
        finished: finishedUpdated
      };
    case lettersTypes.NEW_GAME:
      return {
        currentPosition: 0,
        finished: false,
        firstLine: JSON.parse(JSON.stringify(copyIS.firstLine))
      };
    default:
      return state;
  }
};

export default lettersReducer;
