import React, { Component } from "react";
import BoyImg from "../../../images/boy.png";

export class Boy extends Component {
  constructor() {
    super();
    this.state = {
      tasks: [
        {
          n1: Math.floor(Math.random() * 9) + 1,
          n2: Math.floor(Math.random() * 9) + 1,
          isDone: false
        },
        {
          n1: Math.floor(Math.random() * 9) + 1,
          n2: Math.floor(Math.random() * 9) + 1,
          isDone: false
        },
        {
          n1: Math.floor(Math.random() * 9) + 1,
          n2: Math.floor(Math.random() * 9) + 1,
          isDone: false
        }
      ],
      isDone: false
    };
  }

  handleCalculation = (e, task) => {
    if (
      Number(e.target.value) ===
      Number(this.state.tasks[task].n1 + this.state.tasks[task].n2)
    ) {
      const copyTasks = [...this.state.tasks];
      copyTasks[task].isDone = true;
      this.setState({ tasks: copyTasks });
    } else {
      const copyTasks = [...this.state.tasks];
      copyTasks[task].isDone = false;
      this.setState({ tasks: copyTasks });
    }
    let done = true;
    for (let i = 0; i < this.state.tasks.length; i++) {
      if (!this.state.tasks[i].isDone) {
        done = false;
      }
    }
    done ? this.setState({ isDone: true }) : this.setState({ isDone: false });
  };
  render() {
    return (
      <div className="minigame-holder">
        <div>
          <img src={BoyImg} alt={BoyImg} />
          <h2>Can you help me do my math homework?</h2>
          <div>
            {this.state.tasks.map((task, index) => (
              <div key={index} className="task">
                <p>
                  {task.n1} + {task.n2} ={" "}
                </p>
                <input
                  type="number"
                  onChange={event => {
                    this.handleCalculation(event, index);
                  }}
                />
              </div>
            ))}
            <button disabled={!this.state.isDone} onClick={this.props.endGame}>
              DONE
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Boy;
