import gameTypes from "./game.types";
const INITIAL_STATE = {
  startScreen: true,
  gameOver: false,
  wellDone: false,
  activeGame: false,
  keyboardBlocked: false
};

const gameReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case gameTypes.NEW_GAME_SCREEN:
      return {
        ...state,
        gameOver: false,
        startScreen: false,
        activeGame: true,
        wellDone: false
      };
    case gameTypes.GAME_OVER:
      return { ...state, gameOver: true };
    case gameTypes.BLOCK_KEYS:
      return {
        ...state,
        keyboardBlocked: (state.keyboardBlocked = !state.keyboardBlocked)
      };
    default:
      return state;
  }
};

export default gameReducer;
