import React from "react";
import "./gamebutton.style.scss";

const Gamebutton = props => {
  return (
    <div>
      <img
        src={props.image}
        alt={props.image}
        className={`game-button ${props.isActive ? "active" : null}`}
        onClick={props.isActive ? props.openGame : null}
      />
    </div>
  );
};

export default Gamebutton;
