import { combineReducers } from "redux";
import lettersReducer from "./letters/letters.reducer";
import gameReducer from "./game/game.reducer";
import keyboardReducer from "./keyboard/keyboard.reducer";
import minigamesReducer from "./minigames/minigames.reducer";

export default combineReducers({
  minigames: minigamesReducer,
  letters: lettersReducer,
  game: gameReducer,
  keyboard: keyboardReducer
});
