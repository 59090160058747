import React, { Component } from "react";
import "./minigames.style.scss";
import Gamebutton from "./gamebutton/gamebutton.component";
import { connect } from "react-redux";
import {
  setActiveGame,
  setAlarmGame
} from "../../redux/minigames/minigames.actions";
import Boy from "./boy/boy.component";
import { blockKeyboard } from "../../redux/game/game.actions";
import Boyimage from "../../images/boy.png";
import Babyimage from "../../images/baby.webp";
import Motherinlaw from "../../images/tasta.png";
import Dog from "../../images/dog.png";

class Minigames extends Component {
  handleGame = game => {
    this.props.setActiveMinigame(game);
  };
  closeGame = () => {
    this.props.setActiveMinigame(null);
    this.props.setAlarmGame(null);
    this.props.blockKeyboard();
  };
  render() {
    return (
      <div>
        <Gamebutton
          game="boy"
          isActive={this.props.alarmGame === "boy"}
          image={Boyimage}
          openGame={() => {
            this.handleGame("boy");
          }}
        />
        <Gamebutton
          game="baby"
          image={Babyimage}
          isActive={this.props.alarmGame === "baby"}
          openGame={() => {
            this.handleGame("baby");
          }}
        />
        <Gamebutton
          game="mother-in-law"
          image={Motherinlaw}
          isActive={this.props.alarmGame === "motherinlaw"}
          openGame={() => {
            this.handleGame("baby");
          }}
        />
        <Gamebutton
          game="dog"
          image={Dog}
          isActive={this.props.alarmGame === "dog"}
          openGame={() => {
            this.handleGame("baby");
          }}
        />

        {this.props.activeMiniGame === "boy" ? (
          <Boy endGame={this.closeGame} />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activeMiniGame: state.minigames.activeMinigame,
  alarmGame: state.minigames.alarmGame
});

const mapDispatchToProps = dispatch => ({
  setActiveMinigame: game => dispatch(setActiveGame(game)),
  setAlarmGame: game => dispatch(setAlarmGame(game)),
  blockKeyboard: () => dispatch(blockKeyboard())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Minigames);
