import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import "./time.style.scss";
import { newGame } from "../../redux/letters/letters.actions";
import {
  newGameScreen,
  gameOverScreen,
  blockKeyboard
} from "../../redux/game/game.actions";
import Rakija from "./rakija/rakija.component";
import {
  reorderTopKeys,
  reorderMidKeys,
  reorderBotKeys,
  blurKeys,
  keysReset
} from "../../redux/keyboard/keyboard.actions";
import {
  setAlarmGame,
  setActiveGame
} from "../../redux/minigames/minigames.actions";

const Time = props => {
  const [width, setwidth] = useState(200);
  const [rakije, setRakije] = useState(0);
  const startNewGame = () => {
    setwidth(200);
    setRakije(0);
    if (props.keyboardBlocked) {
      props.blockKeyboard();
    }
    props.setActiveMinigame(null);
    props.setAlarmGame(null);
    props.newGameScreen();
    props.newGame();
    props.keysReset();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setwidth(width => width - 4);
    }, 1000);
    if (width === 120) {
      props.blockKeyboard();
      props.setAlarmGame("boy");
    }
    if (width <= 0) {
      clearInterval(interval);
      props.gameOverScreen();
      //   props.gameOverScreen(() => {
      //props.newGame();
      //   });
    }
    return () => {
      clearInterval(interval);
    };
  }, [width]);

  useEffect(() => {
    if (rakije === 1) {
      props.reorderTop();
    }
    if (rakije === 2) {
      props.reorderMid();
    }
    if (rakije === 3) {
      props.reorderBot();
    }
    if (rakije === 4) {
      props.blurKeys();
    }
  }, [rakije]);

  const increaseTime = () => {
    setRakije(rakije => rakije + 1);
    if (width < 170) {
      setwidth(width => width + 30);
    } else {
      setwidth(width => (width = 200));
    }
  };
  return (
    <div className="left-content">
      {props.gameOver ? (
        <div className="gameover-screen">
          <div>
            <h2>Game over</h2>
            <button onClick={startNewGame}>Try again</button>
          </div>
        </div>
      ) : null}
      {props.finished ? (
        <div className="win-screen">
          <div>
            <h2>well done!</h2>
            <button onClick={startNewGame}>Play again</button>
          </div>
        </div>
      ) : null}
      <p className="deadline">Deadline</p>
      <div className="time">
        <div className="progress" style={{ width: width + "px" }}></div>
        <div className="progress-h"></div>
      </div>
      <Rakija addRakija={increaseTime} rakije={rakije} />
    </div>
  );
};
const mapStateToProps = state => ({
  currentPosition: state.letters.currentPosition,
  gameOver: state.game.gameOver,
  wellDone: state.game.wellDone,
  finished: state.letters.finished,
  keyboardBlocked: state.game.keyboardBlocked
});
const mapDispatchToProps = dispatch => ({
  newGame: () => dispatch(newGame()),
  newGameScreen: () => dispatch(newGameScreen()),
  gameOverScreen: () => dispatch(gameOverScreen()),
  reorderTop: () => dispatch(reorderTopKeys()),
  reorderMid: () => dispatch(reorderMidKeys()),
  reorderBot: () => dispatch(reorderBotKeys()),
  blurKeys: () => dispatch(blurKeys()),
  keysReset: () => dispatch(keysReset()),
  blockKeyboard: () => dispatch(blockKeyboard()),
  setAlarmGame: game => dispatch(setAlarmGame(game)),
  setActiveMinigame: game => dispatch(setActiveGame(game))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Time);
