import React, { Component } from "react";
import "./screen.style.scss";
import { connect } from "react-redux";

export class Screen extends Component {
  constructor() {
    super();
  }
  render() {
    return (
      <div>
        {this.props.finished ? (
          <div className="well-done">Well Done</div>
        ) : (
          <div className="screen">
            <div className="code">
              {this.props.firstLine
                ? this.props.firstLine.map((letter, index) => (
                    <span
                      className={`letter ${letter.active ? "active" : ""} ${
                        letter.done ? "done" : ""
                      }`}
                      key={letter + index}
                    >
                      {letter.val}
                    </span>
                  ))
                : null}
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  firstLine: state.letters.firstLine,
  finished: state.letters.finished
});

export default connect(mapStateToProps)(Screen);
