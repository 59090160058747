import lettersTypes from "./letters.types";
export const setActiveLetter = letter => {
  return {
    type: lettersTypes.SET_ACTIVE_LETTER,
    payload: letter
  };
};
export const moveForvard = () => {
  return {
    type: lettersTypes.MOVE_FORVARD
  };
};
export const newGame = () => {
  return {
    type: lettersTypes.NEW_GAME
  };
};
