import keyboardTypes from "./keyboard.types";
export const reorderTopKeys = () => {
  return {
    type: keyboardTypes.REORDER_TOP
  };
};
export const reorderMidKeys = () => {
  return {
    type: keyboardTypes.REORDER_MID
  };
};
export const reorderBotKeys = () => {
  return {
    type: keyboardTypes.REORDER_BOT
  };
};
export const blurKeys = () => {
  return {
    type: keyboardTypes.BLUR_KEYS
  };
};
export const keysReset = () => {
  return {
    type: keyboardTypes.KEYS_RESET
  };
};
