import minigamesTypes from "./minigames.types";
export const setActiveGame = game => {
  return {
    type: minigamesTypes.SET_ACTIVE_GAME,
    payload: game
  };
};
export const setAlarmGame = game => {
  return {
    type: minigamesTypes.SET_ALARM_GAME,
    payload: game
  };
};
